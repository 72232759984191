import React, { useState, useEffect } from "react";
import {
  getFactories,
  createFactory,
  updateFactory,
  deleteFactory,
  assignUser, // Kullanıcı atama metodu
} from "../../Api/Factories";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditNoteTwoTone from "@mui/icons-material/EditNoteTwoTone";
import { useNotification } from "../../context/NotificationContext";
import ShowError from "../../components/global/ShowError";
import Loading from "../../components/global/Loading";
import { getMembers } from "../../Api/Members";
import Autocomplete from "@mui/material/Autocomplete";

const Factories = () => {
  const { showNotification } = useNotification();
  const [factories, setFactories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFactory, setSelectedFactory] = useState(null);
  const [newFactory, setNewFactory] = useState({ name: "", user: null });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [factoryIdToDelete, setFactoryIdToDelete] = useState(null);
  const [members, setMembers] = useState([]);

  // Fetch factories from the server
  const fetchFactories = async () => {
    try {
      const response = await getFactories();
      setFactories(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch members and filter only factory users
  const fetchMembers = async () => {
    try {
      const response = await getMembers();
      const factoryUsers = response.data.filter(
        member => member.role === "factoryUser"
      );
      setMembers(factoryUsers);
    } catch (error) {
      console.log("Error fetching members:", error);
    }
  };

  useEffect(() => {
    fetchFactories();
    fetchMembers();
  }, []);

  const handleDialogOpen = (factory = null) => {
    setSelectedFactory(factory);
    setNewFactory({
      name: factory ? factory.name : "",
      user:
        factory && factory.users && factory.users.length > 0
          ? factory.users[0]
          : null, // Eğer kullanıcı atanmışsa set et
    });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedFactory(null);
  };

  const handleConfirmDialogOpen = id => {
    setFactoryIdToDelete(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
    setFactoryIdToDelete(null);
  };

  const handleDeleteClick = async () => {
    try {
      await deleteFactory(factoryIdToDelete);
      showNotification("Factory deleted successfully!", "success");
      fetchFactories();
    } catch (error) {
      console.log("Error deleting factory:", error);
    } finally {
      handleConfirmDialogClose();
    }
  };

  const handleSaveFactory = async () => {
    try {
      if (selectedFactory) {
        // Fabrika güncelleniyor
        await updateFactory(selectedFactory._id, { name: newFactory.name });

        if (
          newFactory.user &&
          selectedFactory.users[0]?._id !== newFactory.user._id
        ) {
          // Kullanıcı değişmişse kullanıcı atama işlemi
          await assignUser({
            factoryId: selectedFactory._id,
            factoryUserId: newFactory.user._id,
          });
        }
      } else {
        // Yeni fabrika oluşturuluyor
        const newFactoryResponse = await createFactory({
          name: newFactory.name,
        });

        if (newFactory.user) {
          // Kullanıcı atanıyor
          await assignUser({
            factoryId: newFactoryResponse.data._id,
            factoryUserId: newFactory.user._id,
          });
        }
      }

      fetchFactories();
      handleDialogClose();
      showNotification("Factory saved successfully!", "success");
    } catch (error) {
      console.log("Error saving factory:", error);
    }
  };

  if (error) {
    return <ShowError error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ height: 700, width: "100%" }}>
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDialogOpen()}
            sx={{ color: "white!important", my: 2 }}
          >
            Add Factory
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        rows={factories}
        columns={[
          { field: "name", headerName: "Name", flex: 1, editable: true },
          {
            field: "actions",
            headerName: "Actions",
            renderCell: params => (
              <Box>
                <EditNoteTwoTone
                  style={{ cursor: "pointer", marginRight: 8 }}
                  onClick={() => handleDialogOpen(params.row)}
                />
                <DeleteTwoToneIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleConfirmDialogOpen(params.row._id)}
                />
              </Box>
            ),
          },
        ]}
        getRowId={row => row._id}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        autoHeight
      />

      {/* Create/Edit Factory Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          {selectedFactory ? "Edit Factory" : "Add Factory"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={newFactory.name}
            onChange={e =>
              setNewFactory({ ...newFactory, name: e.target.value })
            }
          />

          {/* Autocomplete for assigning a factory user */}
          <Autocomplete
            options={members}
            getOptionLabel={option => option.name}
            value={newFactory.user}
            onChange={(event, newValue) =>
              setNewFactory({ ...newFactory, user: newValue })
            }
            isOptionEqualToValue={(option, value) => option._id === value?._id}
            renderInput={params => (
              <TextField
                {...params}
                label="Assign Factory User"
                margin="normal"
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveFactory}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this factory?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Factories;
