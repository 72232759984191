import React from "react";
import { Grid, Typography, Box } from "@mui/material";

const RfqInfoCard = ({ rfqDetail }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        borderRadius: "5px",
        boxShadow: "0px 0px 10px 0px #e0e0e0",
        background: "url('/background.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: "40px 30px 30px 10px",
        mt: 1,
        p: 2,
        ml: 0,
        mr: 0,
        width: "100%!important",
      }}
    >
      {/* Rfq Info Card */}

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between!important",
          }}
        >
          <Box>
            <img src="/odak-logo.png" alt="logo" />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* Customer Code */}
            <Grid
              container
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%", // İçeriği tam genişletmek için
                    height: "100%", // İçeriği tam yükseklikte tutmak için
                  }}
                  variant="body2"
                >
                  Product Code
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%", // İçeriği tam genişletmek için
                    height: "100%", // İçeriği tam yükseklikte tutmak için
                  }}
                  variant="body2"
                >
                  {rfqDetail.generalInfo?.customerCode}
                </Typography>
              </Grid>
            </Grid>

            {/* Odak Code */}
            <Grid
              container
              sx={{
                mb: 2,
              }}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  Odak Code
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  {rfqDetail.generalInfo?.odakCode}
                </Typography>
              </Grid>
            </Grid>

            {/* Material */}
            <Grid
              container
              sx={{
                mb: 2,
              }}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  Material
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  {rfqDetail.materials?.ccl}
                </Typography>
              </Grid>
            </Grid>

            {/* Material Thickness */}
            <Grid
              container
              sx={{
                mb: 2,
              }}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  Material Thckn
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  {rfqDetail.materials?.cclThickness}
                </Typography>
              </Grid>
            </Grid>

            {/* Copper Thickness */}
            <Grid
              container
              sx={{
                mb: 2,
              }}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  Copper Thckn
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  {rfqDetail.materials?.copperThickness}
                </Typography>
              </Grid>
            </Grid>

            {/* Layers */}
            <Grid
              container
              sx={{
                mb: 2,
              }}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  Layers
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  {rfqDetail.materials?.layers}
                </Typography>
              </Grid>
            </Grid>

            {/* Finishing */}
            <Grid
              container
              sx={{
                mb: 2,
              }}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  Finishing
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  {rfqDetail.materials?.finishing}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* Soldermask */}
            <Grid
              container
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    flexDirection: "column", // Metni dikey olarak hizalamak için
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                  variant="body2"
                >
                  Soldermask <br /> TOP / BOT
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body2"
                    >
                      {rfqDetail.materials?.lpiColorTop}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body2"
                    >
                      {rfqDetail.materials?.lpiColorBot}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Legend Print */}
            <Grid
              container
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    flexDirection: "column", // Metni dikey olarak hizalamak için
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                  variant="body2"
                >
                  Legend Print <br /> TOP / BOT
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body2"
                    >
                      {rfqDetail.materials?.legendPrintTop}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body2"
                    >
                      {rfqDetail.materials?.legendPrintBot}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Special Print */}
            <Grid
              container
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    flexDirection: "column", // Metni dikey olarak hizalamak için
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                  variant="body2"
                >
                  Special Print <br /> TOP / BOT
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body2"
                    >
                      {rfqDetail.processes?.specialPrints1}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      variant="body2"
                    >
                      {rfqDetail.processes?.specialPrints2}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Quantities */}
            <Grid
              container
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    flexDirection: "column", // Metni dikey olarak hizalamak için
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                  variant="body2"
                >
                  Quantities
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="body2"
                >
                  {[
                    rfqDetail.amounts?.extraAmounts[0],
                    rfqDetail.amounts?.extraAmounts[1],
                    rfqDetail.amounts?.extraAmounts[2],
                    rfqDetail.amounts?.extraAmounts[3],
                    rfqDetail.amounts?.extraAmounts[4],
                    rfqDetail.amounts?.extraAmounts[5],
                    rfqDetail.amounts?.extraAmounts[6],
                    rfqDetail.amounts?.extraAmounts[7],
                    rfqDetail.amounts?.extraAmounts[8],
                    rfqDetail.amounts?.extraAmounts[9],
                    rfqDetail.amounts?.extraAmounts[10],
                    rfqDetail.amounts?.extraAmounts[11],
                    rfqDetail.amounts?.extraAmounts[12],
                    rfqDetail.amounts?.extraAmounts[13],
                    rfqDetail.amounts?.extraAmounts[14],
                    rfqDetail.amounts?.extraAmounts[15],
                    rfqDetail.amounts?.extraAmounts[16],
                    rfqDetail.amounts?.extraAmounts[17],
                    rfqDetail.amounts?.extraAmounts[18],
                    rfqDetail.amounts?.extraAmounts[19],
                    rfqDetail.amounts?.extraAmounts[20],
                  ]
                    .filter((amount) => amount)
                    .join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* Special Process */}
            <Grid
              container
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                  variant="body2"
                >
                  Special Process
                </Typography>
              </Grid>

              {rfqDetail.processes?.specialProcesses?.map(
                (process, index) =>
                  index !== 0 && (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={12}
                      sx={{
                        display: "flex",
                        alignItems: "stretch",
                        height: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          border: "1px solid #ddd",
                          padding: "5px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                        }}
                        variant="body2"
                      >
                        {process}
                      </Typography>
                    </Grid>
                  )
              )}
            </Grid>
            {/* DIMENSIONS-Unit ( mm )  Pcb Size, Panel Size, Panel*/}
            <Grid
              container
              spacing={2}
              sx={{
                mb: 2,
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white!important",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    padding: "5px",
                    borderRadius: "5px",
                    background: "#ef7757",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                  variant="body2"
                >
                  DIMENSIONS-Unit ( mm )
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "100%",
                }}
              >
                <Grid container spacing={1}>
                  {/* PCB Size */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            border: "1px solid #ddd",
                            padding: "5px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          variant="body2"
                        >
                          PCB Size
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            border: "1px solid #ddd",
                            padding: "5px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          variant="body2"
                        >
                          {`${rfqDetail.dimensions?.pcbSizeX} x ${rfqDetail.dimensions?.pcbSizeY}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Panel Size */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            border: "1px solid #ddd",
                            padding: "5px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          variant="body2"
                        >
                          Panel Size
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            border: "1px solid #ddd",
                            padding: "5px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          variant="body2"
                        >
                          {`${rfqDetail.dimensions?.panelSizeX} x ${rfqDetail.dimensions?.panelSizeY}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Panel Count */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            border: "1px solid #ddd",
                            padding: "5px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          variant="body2"
                        >
                          Panel Count
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            border: "1px solid #ddd",
                            padding: "5px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          variant="body2"
                        >
                          {`${rfqDetail.dimensions?.panelX} x ${rfqDetail.dimensions?.panelY}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* End of Rfq Info Card */}
    </Grid>
  );
};

export default RfqInfoCard;
