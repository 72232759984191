import { fetchData } from "../utils/fetchData";
import { deleteData } from "../utils/deleteData";
import { putData } from "../utils/putData";
import { postData } from "../utils/postData";

export const getMembers = async () => {
  const response = await fetchData("/users");
  return response;
};

export const getMember = async id => {
  const response = await fetchData(`/users/${id}`);
  return response;
};

export const deleteMember = async id => {
  const response = await deleteData(`/users/${id}`);
  return response;
};

export const updateMember = async (id, data) => {
  const response = await putData(`/users/${id}`, data);
  return response;
};

export const createMember = async data => {
  const response = await postData("/auth/register", data);
  return response;
};

export const assignManager = async data => {
  const response = await postData(`/users/assign-manager`, data);
  return response;
};

export const removeManager = async (id, data) => {
  const response = await putData(`/users/remove-manager`, data);
  return response;
};

export const getMembersByRoleFactory = async () => {
  const response = await fetchData(`/users/by-factory-role`);
  return response;
};
