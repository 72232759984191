import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import SyncIcon from "@mui/icons-material/Sync";

const FactoryUserGuide = () => {
  const sections = [
    {
      title: "1. Logging In",
      icon: <InfoIcon color="primary" fontSize="large" />,
      content: (
        <>
          To access the system, use your registered email address and password.
          If you don’t have an account, please contact the administrator. Ensure
          your credentials are correct; otherwise, you might be locked out after
          multiple failed attempts.
        </>
      ),
    },
    {
      title: "2. Viewing RFQs",
      icon: <VisibilityIcon color="primary" fontSize="large" />,
      content: (
        <>
          When you receive an RFQ email notification, click the{" "}
          <strong>Show RFQ</strong> button to view the details. If you are
          already logged in and your session is valid, you will be redirected to
          the RFQ details page. Otherwise, you will need to log in first. On the
          RFQ details page, review the specifications and requirements before
          proceeding.
        </>
      ),
    },
    {
      title: "3. Submitting Quotes",
      icon: <CreateIcon color="primary" fontSize="large" />,
      content: (
        <>
          To submit your quote, fill out the fields on the RFQ details page:
          <List>
            <ListItem>
              <ListItemText
                primary="Lead Time (LT)"
                secondary="Specify the delivery time (in days)."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Tooling Cost"
                secondary="Enter the tooling cost for the project."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="eTest Cost"
                secondary="Provide the electronic testing cost per unit."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Unit Price"
                secondary="Specify the unit price for the RFQ."
              />
            </ListItem>
          </List>
          Once completed, click <strong>Submit Quotatio</strong>. Ensure all
          details are accurate, as these will be sent to the customer for
          review.
        </>
      ),
    },
    {
      title: "4. Revising and Resubmitting Quotes",
      icon: <SyncIcon color="primary" fontSize="large" />,
      content: (
        <>
          If the customer requests a revision, you will receive a notification.
          Review the target price and other adjustments, then revise your quote
          accordingly. After making the necessary updates, resubmit your quote.
          This process will continue until either party accepts the offer.
        </>
      ),
    },
  ];

  return (
    <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom align="center">
        Factory User Guide
      </Typography>
      {sections.map((section, index) => (
        <Card variant="outlined" sx={{ marginBottom: "20px" }} key={index}>
          <CardHeader
            avatar={section.icon}
            title={
              <Typography variant="h6" component="div">
                {section.title}
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="body1">{section.content}</Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default FactoryUserGuide;
