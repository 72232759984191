import { Typography, useTheme } from "@mui/material";
import React from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import {
  AdminPanelSettingsTwoTone,
  DashboardCustomizeTwoTone,
  FactoryTwoTone,
  PaidTwoTone,
  PaymentsTwoTone,
  SupervisorAccountTwoTone,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { getUserCredentials } from "../../utils/getUserCredentials";

const SideNav = () => {
  const user = getUserCredentials();
  const theme = useTheme();
  const location = useLocation();

  const menuPermissions = {
    rfq: ["factoryUser", "admin", "cr", "manager", "sales", "technical"],
    customers: ["admin", "cr", "manager", "sales", "technical"],
    members: ["admin", "cr", "manager", "sales", "technical"],
    factories: ["admin", "cr", "manager", "sales", "technical"],
  };

  const hasPermission = permissionKey =>
    menuPermissions[permissionKey].includes(user.role);

  return (
    <Sidebar
      style={{
        height: "100%",
        top: "auto",
      }}
      breakPoint="md"
      backgroundColor={theme.palette.white.main}
    >
      <Menu
        menuItemStyles={{
          button: ({ active }) => ({
            backgroundColor: active
              ? theme.palette.neutral.medium
              : "transparent",
          }),
        }}
      >
        {hasPermission("rfq") && (
          <MenuItem
            active={
              location.pathname === "/" || location.pathname.startsWith("/rfqs")
            }
            component={<Link to={"/"} />}
            icon={<DashboardCustomizeTwoTone />}
          >
            <Typography variant="body">Rfq</Typography>
          </MenuItem>
        )}
        {hasPermission("customers") && (
          // heading
          <MenuItem
            active={location.pathname === "/customers"}
            component={<Link to={"/customers"} />}
            icon={<SupervisorAccountTwoTone />}
          >
            <Typography variant="body">Customers</Typography>
          </MenuItem>
        )}
        {hasPermission("members") && (
          <MenuItem
            active={location.pathname === "/members"}
            component={<Link to={"/members"} />}
            icon={<AdminPanelSettingsTwoTone />}
          >
            <Typography variant="body">Members</Typography>
          </MenuItem>
        )}
        {hasPermission("factories") && (
          <MenuItem
            active={location.pathname === "/factories"}
            component={<Link to={"/factories"} />}
            icon={<FactoryTwoTone />}
          >
            <Typography variant="body">Factories</Typography>
          </MenuItem>
        )}
      </Menu>
    </Sidebar>
  );
};

export default SideNav;
