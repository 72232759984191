import React, { useState, useEffect } from "react";
import Loading from "../../components/global/Loading";
import {
  getCustomers,
  getCustomer,
  createCustomer,
  deleteCustomer,
  updateCustomer,
} from "../../Api/Customers"; // API fonksiyonları
import { DataGrid } from "@mui/x-data-grid";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import EditNoteTwoTone from "@mui/icons-material/EditNoteTwoTone";
import ShowError from "../../components/global/ShowError";
import { useNotification } from "../../context/NotificationContext";

const adminId = "67013281da37bebc5acc7605";

const Customers = () => {
  const { showNotification } = useNotification();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [newCustomer, setNewCustomer] = useState({
    name: "",
  });
  const [customerIdToDelete, setCustomerIdToDelete] = useState(null);

  const handleDialogOpen = (customer = null) => {
    setSelectedCustomer(customer);
    setNewCustomer(customer || { name: "" });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedCustomer(null);
  };

  const handleConfirmDialogOpen = id => {
    setCustomerIdToDelete(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
    setCustomerIdToDelete(null);
  };

  const handleDeleteClick = async () => {
    try {
      await deleteCustomer(customerIdToDelete);
      showNotification("Customer deleted successfully!", "success");
      fetchCustomers();
    } catch (error) {
      console.log("Error deleting customer:", error);
    } finally {
      handleConfirmDialogClose();
    }
  };

  const handleSaveCustomer = async () => {
    try {
      if (selectedCustomer) {
        await updateCustomer(selectedCustomer._id, newCustomer);
      } else {
        await createCustomer(newCustomer);
      }
      fetchCustomers();
      handleDialogClose();
      showNotification("Customer saved successfully!", "success");
    } catch (error) {
      console.log("Error saving customer:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await getCustomers();
      setCustomers(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  if (error) {
    return <ShowError error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ height: 700, width: "100%" }}>
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDialogOpen()}
            sx={{ color: "white!important", my: 2 }}
          >
            Add Customer
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        rows={customers}
        columns={[
          { field: "name", headerName: "Name", flex: 1, editable: true },
          {
            field: "actions",
            headerName: "Actions",
            renderCell: params => (
              <Box>
                <EditNoteTwoTone
                  style={{ cursor: "pointer", marginRight: 8 }}
                  onClick={() => handleDialogOpen(params.row)}
                />
                <DeleteTwoToneIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleConfirmDialogOpen(params.row._id)}
                />
              </Box>
            ),
          },
        ]}
        getRowId={row => row._id}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        autoHeight
      />

      {/* Create/Edit Customer Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          {selectedCustomer ? "Edit Customer" : "Add Customer"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={newCustomer.name}
            onChange={e =>
              setNewCustomer({ ...newCustomer, name: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveCustomer}
            variant="contained"
            color="primary"
            sx={{ color: "white!important" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this customer?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Customers;
