import React, { useState, useEffect } from "react";
import Loading from "../../components/global/Loading";
import {
  getMembers,
  getMember,
  createMember,
  deleteMember,
  updateMember,
  assignManager,
} from "../../Api/Members";
import { DataGrid } from "@mui/x-data-grid";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import EditNoteTwoTone from "@mui/icons-material/EditNoteTwoTone";
import ShowError from "../../components/global/ShowError";
import { useNotification } from "../../context/NotificationContext";

const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
  { value: "technical", label: "Technical" },
  { value: "sales", label: "Purchase" },
  { value: "cr", label: "CR" },
  { value: "factoryUser", label: "Factory User" },
];

const Members = () => {
  const { showNotification } = useNotification();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [newMember, setNewMember] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
  });
  const [selectedManager, setSelectedManager] = useState("");
  const [memberIdToDelete, setMemberIdToDelete] = useState(null);

  const adminId = "67013281da37bebc5acc7605";

  const handleDialogOpen = (member = null) => {
    setSelectedMember(member);
    setNewMember(member || { name: "", email: "", role: "", password: "" });

    // Eğer kullanıcının zaten bir yöneticisi varsa, varsayılan olarak seç
    setSelectedManager(member?.manager || "");
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedMember(null);
    setSelectedManager("");
  };

  const handleConfirmDialogOpen = id => {
    setMemberIdToDelete(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
    setMemberIdToDelete(null);
  };

  const handleDeleteClick = async () => {
    try {
      await deleteMember(memberIdToDelete);
      showNotification("Member deleted successfully!", "success");
      fetchMembers();
    } catch (error) {
      console.log("Error deleting member:", error);
    } finally {
      handleConfirmDialogClose();
    }
  };

  const handleSaveMember = async () => {
    try {
      if (selectedMember) {
        await updateMember(selectedMember._id, newMember);

        if (newMember.role !== "factoryUser" && selectedManager) {
          await assignManager({
            adminId,
            targetUserId: selectedMember._id,
            managerId: selectedManager,
          });
        }
      } else {
        await createMember(newMember);
      }
      fetchMembers();
      handleDialogClose();
      showNotification("Member saved successfully!", "success");
    } catch (error) {
      console.log("Error saving member:", error);
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await getMembers();
      setMembers(response.data);
      setManagers(response.data.filter(member => member.role === "manager"));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  if (error) {
    return <ShowError error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ height: 700, width: "100%" }}>
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDialogOpen()}
            sx={{ color: "white!important", my: 2 }}
          >
            Add Member
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        rows={members}
        columns={[
          { field: "name", headerName: "Name", flex: 1, editable: true },
          { field: "email", headerName: "Email", flex: 1, editable: true },
          {
            field: "role",
            headerName: "Role",
            flex: 1,
            editable: true,
            renderCell: params =>
              roleOptions.find(option => option.value === params.value)?.label,
          },
          {
            field: "actions",
            headerName: "Actions",
            renderCell: params => (
              <Box>
                <EditNoteTwoTone
                  style={{ cursor: "pointer", marginRight: 8 }}
                  onClick={() => handleDialogOpen(params.row)}
                />
                <DeleteTwoToneIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleConfirmDialogOpen(params.row._id)}
                />
              </Box>
            ),
          },
        ]}
        getRowId={row => row._id}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        autoHeight
      />

      {/* Create/Edit Member Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          {selectedMember ? "Edit Member" : "Add Member"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={newMember.name}
            onChange={e => setNewMember({ ...newMember, name: e.target.value })}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={newMember.email}
            onChange={e =>
              setNewMember({ ...newMember, email: e.target.value })
            }
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={newMember.password}
            onChange={e =>
              setNewMember({ ...newMember, password: e.target.value })
            }
          />
          <TextField
            label="Role"
            select
            fullWidth
            margin="normal"
            value={newMember.role}
            onChange={e => setNewMember({ ...newMember, role: e.target.value })}
          >
            {roleOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {/* Set Manager Section - Only show if role is not factoryUser */}
          {newMember.role !== "factoryUser" && (
            <TextField
              label="Set Manager"
              select
              fullWidth
              margin="normal"
              value={selectedManager}
              onChange={e => setSelectedManager(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {managers.map(manager => (
                <MenuItem key={manager._id} value={manager._id}>
                  {manager.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveMember}
            variant="contained"
            color="primary"
            sx={{ color: "white!important" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this member?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Members;
