import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { getAllRfqs, deleteRfq, uploadRfqs } from "../../Api/Rfq";
import Loading from "../../components/global/Loading";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
  Divider,
} from "@mui/material";
import UploaderDialog from "./UploaderDialog";
import { getUserCredentials } from "../../utils/getUserCredentials";

const Rfq = () => {
  const role = getUserCredentials().role;
  const [loading, setLoading] = useState(true);
  const [rfqs, setRfqs] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedRfq, setSelectedRfq] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [uploaderOpen, setUploaderOpen] = useState(false); // Uploader dialog state'i

  const fetchRfqs = async (currentPage, size, status = "", search = "") => {
    setLoading(true);
    try {
      const response = await getAllRfqs(currentPage, size, status, search);
      setRfqs(response.data.rfqs);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRfqs(page + 1, pageSize, statusFilter, debouncedSearchTerm);
  }, [page, pageSize, statusFilter, debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const handleDeleteClick = (id) => {
    setSelectedRfq(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedRfq) {
      try {
        await deleteRfq(selectedRfq);
        fetchRfqs(page + 1, pageSize, statusFilter, debouncedSearchTerm);
      } catch (error) {
        console.log("Error deleting RFQ:", error);
      } finally {
        setOpen(false);
        setSelectedRfq(null);
      }
    }
  };

  const handleUpload = async (formData) => {
    try {
      await uploadRfqs(formData);
      // Dosya yükleme işlemi için API çağrısı burada yapılacak
      console.log("Uploading file:", formData);
      fetchRfqs(page + 1, pageSize, statusFilter, debouncedSearchTerm);
      // Backend'e yükleme işlemini gerçekleştirin ve gerekirse fetchRfqs ile tabloyu güncelleyin.
    } catch (error) {
      console.log("File upload failed!", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRfq(null);
  };

  const handleOpenUploader = () => {
    setUploaderOpen(true); // Uploader dialogunu aç
  };

  const handleCloseUploader = () => {
    setUploaderOpen(false); // Uploader dialogunu kapat
  };

  const columns = [
    { field: "rfqNumber", headerName: "#Rfq", width: 75 },
    {
      field: "odakCode",
      headerName: "PCB Code",
      width: 200,
      renderCell: (params) => (
        <Link
          style={{
            cursor: "pointer",
            color: "#ef7757",
            textDecoration: "none",
            fontWeight: "500",
          }}
          to={`/rfqs/${params.row.id}`}
        >
          {params.value}
        </Link>
      ),
    },
    { field: "customerName", headerName: "Customer Name", width: 200 },
    { field: "customerCode", headerName: "Customer Code", width: 300 },
    { field: "status", headerName: "Status", width: 200 },
    { field: "salesPersonName", headerName: "Sales Person", width: 200 },
    {
      headerName: "Date",
      width: 200,
      valueGetter: (params) =>
        new Date(params.row.date).toLocaleDateString("tr-TR"),
    },
    role !== "factoryUser" && {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <DeleteTwoToneIcon
          style={{ cursor: "pointer" }}
          onClick={() => handleDeleteClick(params.row.id)}
        />
      ),
    },
  ];

  const rows = rfqs.map((rfq) => ({
    id: rfq._id,
    odakCode: rfq.generalInfo.odakCode,
    rfqNumber: rfq.rfqNumber,
    customerCode: rfq.generalInfo.customerCode,
    status: rfq.generalInfo.status,
    salesPersonName: rfq.sales?.salesPerson.name || "N/A",
    customerName: rfq.customer?.name || "N/A",
    date: rfq.createdAt,
  }));

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ height: 700, width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        sx={{ backgroundColor: "#f5f5f5", borderRadius: 1 }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small" // Input boyutunu küçülttük
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ maxWidth: 400 }}
        />
        <FormControl
          sx={{
            maxWidth: 400,
          }}
          fullWidth
          size="small" // Select boyutunu küçülttük
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={handleStatusChange}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
            <MenuItem value="waiting for factories">
              Waiting for Factories
            </MenuItem>
            <MenuItem value="offers received">Offers Received</MenuItem>
            <MenuItem value="waiting for re-offer">
              Waiting for Re-Offer
            </MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="waiting for manager approval">
              Waiting for Manager Approval
            </MenuItem>
            <MenuItem value="waiting for sale prices">
              Waiting for Sale Prices
            </MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {role !== "factoryUser" && (
        <Box
          sx={{
            p: 1,
          }}
          mb={2}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenUploader}
            sx={{
              color: "white!important",
              maxWidth: "190px",
              width: "100%",
            }}
          >
            Upload RFQ
          </Button>
        </Box>
      )}
      {role !== "factoryUser" && (
        <UploaderDialog
          open={uploaderOpen}
          onClose={handleCloseUploader}
          title="Upload File"
          renderTable={fetchRfqs} // Dosya yüklendikten sonra tabloyu güncellemek için
          uploads={handleUpload} // Dosya yükleme fonksiyonu
        />
      )}
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={totalRecords}
        pageSize={pageSize}
        page={page}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPage(newPage)}
        loading={loading}
        rowsPerPageOptions={[5, 10, 20, 50]}
        components={{
          Toolbar: GridToolbar,
        }}
        autoHeight
        sx={{
          "& .MuiDataGrid-toolbarContainer": {
            mb: 2, // Toolbar ile üst kenar arasındaki boşluğu artırır
          },
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this RFQ? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Rfq;
