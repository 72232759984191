import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Box, Chip, Typography } from "@mui/material";
import { updateOfferNotes } from "../../Api/Rfq";
import Loading from "../../components/global/Loading";
import { useNotification } from "../../context/NotificationContext";
const TitleBox = ({ title, status }) => (
  <Box
    sx={{
      backgroundColor: "#ef7757",
      width: "100%",
      padding: "10px 20px",
      display: "flex",
      pt: 2,
      borderRadius: "5px",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Typography
      gutterBottom
      sx={{
        fontWeight: "600",
        fontSize: "1.2rem",
        color: "white!important",
      }}
    >
      {title}
    </Typography>
    {status === "waiting for manager approval" && (
      <Chip
        label="Waiting for Manager Approval"
        sx={{ ml: 2, color: "white!important" }}
      />
    )}
  </Box>
);

const FactoryUserNote = ({
  rfq,
  rfqId,
  factoryUserId,
  initialNotes,
  fetchRfqDetail,
}) => {
  const [notes, setNotes] = useState(initialNotes);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();

  useEffect(() => {
    setNotes(initialNotes);
  }, [initialNotes]);

  const handleSaveNotes = async () => {
    setLoading(true);
    try {
      const offerId = rfq?.offers?.[0]?._id;
      await updateOfferNotes(factoryUserId, {
        offerId,
        notes,
      });
      showNotification("Notes updated successfully", "success");
      await fetchRfqDetail(rfqId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) <Loading />;

  return (
    <>
      <Grid item xs={12} md={12}>
        <TitleBox title="Factory Notes" />
      </Grid>
      <Grid
        sx={{
          ml: 2,
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
          borderRadius: "5px",
          p: 2,
          mt: 1,
        }}
        item
        xs={12}
        md={12}
      >
        <TextField
          value={notes}
          onChange={e => setNotes(e.target.value)}
          label="Factory Notes"
          multiline
          rows={4}
          fullWidth
        />

        <Button
          variant="contained"
          color="primary"
          sx={{ color: "white!important", mt: 2 }}
          onClick={handleSaveNotes}
        >
          Save Notes
        </Button>
      </Grid>
    </>
  );
};

export default FactoryUserNote;
