import { fetchData } from "../utils/fetchData";
import { deleteData } from "../utils/deleteData";
import { putData } from "../utils/putData";
import { postData } from "../utils/postData";

const getCustomers = async () => {
  const response = await fetchData("/customers");
  return response;
};

const getCustomer = async (id) => {
  const response = await fetchData(`/customers/${id}`);
  return response;
};

const createCustomer = async (data) => {
  const response = await postData("/customers", data);
  return response;
};

const updateCustomer = async (id, data) => {
  const response = await putData(`/customers/${id}`, data);
  return response;
};

const deleteCustomer = async (id) => {
  const response = await deleteData(`/customers/${id}`);
  return response;
};

export {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
};

// asdasdasd
